<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar0";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Page-terms component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Пользовательское соглашение </h4>
                        <ul class="list-unstyled mt-4">
                            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Дата публикации :</span> 9 Марта, 2021</li>
                        </ul>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Skillrank</router-link>
                                    </li>
                                    <li class="breadcrumb-item"><a href="#">Документы</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Пользовательское соглашение</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Terms & Conditions -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">
                          <h4 class="title"> Cоглашение (оферта) об использовании caйта <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a></h4>

                          <p>Настоящее Пользовательское соглашение регулирует отношения по использованию сайта <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>.</p>

                          <h5 class="card-title"> 1. Общие положения </h5>

                          <p>1.1. Для целей настоящего Пользовательского соглашения, термины и определения имеют следующее значение:</p>
                          <ul>
                          <li>Соглашение – настоящее «Пользовательское соглашение caйта <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>», размещенное в сети Интернет по адресу: <a href="https://skillrank.ru/terms" class="text-primary">https://skillrank.ru/terms</a>.</li>

                          <li>Администрация Сайта – ИП «Гончаренко Игорь Сергеевич», являющийся администратором Сайта.</li>

                          <li>Сайт – принадлежащая Администрации Сайта информационная система, состоящая из совокупности графических и информационных материалов, а также программного кода и баз данных, обеспечивающих доступность таких материалов в сети Интернет по адресу: <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>.</li>

                          <li>Сервисы – функциональные возможности, инструменты, услуги, доступные для Пользователя на Сайте.</li>

                          <li>Пользователь – лицо, обладающее полной дееспособностью, использующее сервисы Сайта либо получившее доступ к Сайту в любой форме.</li>

                          <li>Стороны – Администрация Сайта и Пользователь при совместном упоминании.</li>

                          <li>HR – Пользователь, использующий Сайт с целью поиска работников.</li>

                          <li>Кандидат – лицо, разместившее информацию о себе, включая контактную информацию, с целью поиска работы (трудоустройства либо выполнения работ/оказания услуг на ином законном основании) либо Пользователь, который проходит Интервью с помощью Сервисов Сайта.</li>

                          <li>Личный кабинет – персональный раздел Пользователя на Сайте, в котором Пользователю доступно управление отдельными сервисами.</li>

                          <li>Учетные данные – уникальный логин и пароль, используемые для доступа в Личный кабинет.</li>

                          <li>Контент – информация, представленная в текстовом, графическом форматах на Сайте, являющаяся его наполнением.</li>

                          <li>Приглашение – Сервис Сайта, позволяющий HR отправить Кандидату приглашение пройти интервью.</li>

                          <li>Отклик – Сервис Сайта, позволяющий Кандидату отправить HR резюме, показывающий заинтересованность в определенной опубликованной вакансии или возможных неопубликованных вакасниях, которые могут возникнуть у HR.</li>
                          </ul>
                          <p>1.2. Для целей настоящего Соглашения термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе.</p>

                          <p>1.3. Везде, где по тексту Соглашения упоминаются «дни», имеются в виду календарные дни, если Соглашением прямо не предусмотрено иное.</p>

                          <p>1.4. В случае использования в настоящем Соглашении терминов, не определенных выше, определение таких терминов производится в соответствии с текстом Соглашения. При отсутствии однозначного определения термина в тексте Соглашения Стороны руководствуются законодательством Российской Федерации.</p>

                          <p>1.5. Предложение о заключении Соглашения является бессрочным и действует в течение всего времени размещения текста Соглашения на Сайте.</p>

                          <p>1.6. Каждая из Сторон гарантирует, что обладает необходимой право- и дееспособностью, а равно всеми правами и полномочиями, необходимыми и достаточными для заключения и исполнения Соглашения.</p>

                          <p>1.7. Настоящим Пользователь подтверждает, что акцепт (совершение действий по принятию оферты) равносилен подписанию и заключению Соглашения на условиях, изложенных в настоящем документе.</p>

                          <p>1.8. Акцептом оферты является полное и безоговорочное принятие оферты Пользователем путем совершения одного из следующих действий:</p>
                          <ul class="list-unstyled ml-4">
                          <li> 1.8.1. использование сервисов Сайта;</li>

                          <li> 1.8.2. регистрация на Сайте;</li>

                          <li> 1.8.3. авторизация в Личном кабинете;</li>

                          <li> 1.8.4. просмотр Контента;</li>

                          <li>1.8.5. заполнение форм на Сайте;</li>

                          <li>1.8.6. иное использование Сайта.</li>
                          </ul>

                          <p>1.9. Пользователь должен ознакомиться с действующей редакцией Соглашения до начала использования Сайта.</p>

                          <p>1.10. Осуществляя доступ к Сайту, Пользователь в соответствии с Федеральным законом от 13 марта 2006 г. N 38-ФЗ «О рекламе» выражает свое согласие на получение всей рекламной информации, размещенной на Сайте, в том числе, третьими лицами. Пользователь понимает и соглашается, что Сайт не определяет содержание и не несет ответственности за такую информацию, включая веб-ресурсы, ссылки на которые могут содержаться в соответствующих материалах.</p>

                          <h5 class="card-title">2. Предмет Соглашения</h5>

                          <p>2.1. Администрация Сайта предоставляет Пользователю право использовать Сайт (включая Сервисы Сайта) по прямому назначению, в том числе, путем осуществления доступа к Сайту на условиях безвозмездной простой (неисключительной) лицензии, на срок, в течение которого Сайт остается доступным для Пользователя.</p>

                          <p>2.2. Доступ к отдельным Сервисам осуществляется за плату в соответствии с условиями, установленными Правилами оказания услуг.</p>

                          <h5 class="card-title">3. Регистрация на Сайте</h5>

                          <p>3.1. Для организации взаимодействия с Пользователем, а также для возможности использования отдельных Сервисов Администрация Сайта предоставляет возможность регистрации в Личном кабинете.</p>

                          <p>3.2. Регистрация Пользователя на Сайте является бесплатной и добровольной.</p>

                          <p>3.3. Администрация Сайта регистрирует Личный кабинет Пользователя в следующем порядке: используя специальную форму, Пользователь указывает e-mail (логин) и пароль.</p>

                          <p>3.4. Администрация Сайта оставляет за собой право устанавливать требования к паролю (длина, допустимые символы).</p>

                          <p>3.5. Регистрация учетной записи осуществляется на e-mail Пользователя однократно.</p>

                          <p>3.6. Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие законодательству предоставленной информации при регистрации.</p>

                          <p>3.7. Администрация Сайта вправе потребовать от Пользователя подтверждения указанных при регистрации данных, а также запросить подтверждающие документы (в т.ч. документы, удостоверяющие личность).</p>

                          <p>3.8. Администрация Сайта вправе заблокировать или удалить учетную запись Пользователя, а также прекратить или ограничить доступ Пользователя к определенным Сервисам Сайта, в случае предоставления Пользователем недостоверных данных, непредоставления подтверждающих документов, а также при нарушении Пользователем иных условий настоящего Соглашения.</p>

                          <p>3.9. Пользователь обязан следить за сохранностью своих Учетных данных. Любое действие, совершенное из Личного кабинета Пользователя с использованием его Учетных данных, считается действием, совершенным самим Пользователем и устанавливает обязанности и ответственность для Пользователя в отношении таких действий, включая ответственность за нарушение настоящего Соглашения и требований законодательства.</p>

                          <p>3.10. Пользователь не имеет права использовать для доступа на Сайт Учетные данные другого Пользователя.</p>

                          <p>3.11. Пользователь, доступ которого к Сервисам был прекращен, не имеет права создавать новую учетную запись на Сайте без особого разрешения Администрации Сайта.</p>

                          <p>3.12. Просмотр информации, размещенной на Сайте в открытом доступе, не требует регистрации и/или авторизации Пользователя, однако при совершении таких действий Пользователь в любом случае обязан соблюдать положения настоящего Соглашения.</p>

                          <h5 class="card-title">4. Использование Сервисов Сайта</h5>

                          <p>4.1. Принимая условия настоящего Соглашения, Пользователь подтверждает и гарантируете, что использование Сервисов будет осуществляться Пользователем исключительно для целей поиска работы или подбора персонала, с соблюдением положений Соглашения и законодательства РФ.</p>

                          <p>4.2. Администрация Сайта предоставляет Кандидату возможность бесплатного создания и размещения резюме на Сайте, откликаться на вакансии, и получать Приглашения на интервью от HR, посылать свое резюме HR.</p>

                          <p>4.3. Создание и размещение резюме осуществляется путем сохранения его в общей базе данных Сайта, доступной только Администрации Сайта, и его отображения в Личном кабинете HR.</p>

                          <p>4.4. Кандидат соглашается с тем, что HR, для которого он создал и разместил резюме, может сохранить резюме для
                          последующего просмотра вне Сайта посредством скачивания в память ЭВМ или в любое другое хранилище или иным образом.
                          В таком случае HR обязуется не использовать
                            резюме для распространения среди неограниченного круга лиц/для предоставления третьим лицам, и несет
                            ответственность за конфиденциальность данных, содержащихся в резюме.
                          </p>

                          <p>4.5. Кандидат имеет право в любое время без объяснения причины закрыть доступ и/или удалить размещенное им резюме на Сайте.</p>

                          <p>4.6. В качестве имени автора при размещении резюме на Сайте будет указываться имя Кандидата, которое он указал
                            при регистрации на Сайте.</p>

                          <p>4.7. Права на все размещенные на Сайте резюме считаются принадлежащими разместившим их Кандидатами до тех пор,
                            пока не будет оснований считать иначе.</p>

                          <p>4.8. Пользователи Сайта не получают никаких прав на использование резюме других Пользователей
                            вне рамок возможностей, предоставляемых Сайтом, если иное не разрешено самим Пользователем, и несут
                             полную ответственность перед автором за неправомерное использование резюме.</p>

                          <p>4.9. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия,
                            связанные с созданием и размещением резюме на Сайте, в том числе за то, что содержание резюме
                             соответствует требованиям законодательства РФ и не нарушает права и законные интересы третьих лиц.
                              Резюме в целом и каждый из его элементов не должны нарушать интеллектуальные права,
                              права на товарные знаки, средства индивидуализации и/или права на иные объекты интеллектуальной
                              собственности, принадлежащие третьим лицам.
                              Пользователь самостоятельно и за свой счет обязуется урегулировать все претензии третьих лиц,
                              связанные с созданием и размещением резюме на Сайте.</p>

                          <p>4.10. Администрация Сайта имеет право без уведомления Пользователя и без объяснения причин не принимать
                            резюме к размещению на Сайте либо в любое время удалить резюме с Сайта.</p>



                          <h5 class="card-title">5. Права и обязанности Сторон</h5>

                          <p>5.1. Администрация Сайта обязуется:</p>
                          <ul class="list-unstyled ml-4">
                          <li>5.1.1. Предоставлять информацию по вопросам работы с Сайтом посредством электронной почты,
                            а также через службу поддержки Сайта.</li>
                          <li>5.1.2. Воздерживаться от каких-либо действий, способных затруднить осуществление Пользователем предоставленного ему права использования Сайта в установленных Соглашением пределах.</li>
                          <li>5.1.3. В течение 30 дней со дня получения соответствующего письменного уведомления от Пользователя и доказательств нарушения, удалить с Сайта материалы, запрещенные к распространению законодательством РФ либо нарушающие авторские права.</li>
                        </ul>
                          <p>5.2. Пользователь обязуется:</p>
                          <ul class="list-unstyled ml-4">
                          <li> 5.2.1. Использовать Сайт только в пределах тех прав и теми способами, которые предусмотрены в Соглашении.</li>

                          <li> 5.2.2. Не представляться чужим именем и не регистрироваться, используя чужие Учетные данные.</li>

                          <li> 5.2.3. Предоставлять по запросу Администрации Сайта документы и информацию, необходимые для идентификации Пользователя как стороны Соглашения, а также достоверность иных сведений, представленных Пользователем.</li>

                          <li> 5.2.4. Уведомлять в течение 3 (трех) рабочих дней Администрацию Сайта в случае изменения каких-либо данных о нем, предоставленных Администрации Сайта. В случае неисполнения этой обязанности, ответственность за все возникшие последствия в полном объеме несет Пользователь.</li>
- по своему усмотрению и необходимости создавать, изменять, отменять правила
- ограничивать доступ к любой информации на сайте
                          <li> 5.2.5. Незамедлительно информировать Администрацию Сайта обо всех ставших ему известных фактах противоправного использования Сайта третьими лицами.</li>

                          <li> 5.2.6. Использовать Сайт, не нарушая имущественных и/или личных неимущественных прав третьих лиц, а равно запретов и ограничений, установленных применимым правом, включая авторские и смежные права, права на товарные знаки, знаки обслуживания, права на использование изображений граждан.</li>

                          <li> 5.2.7. Не использовать информацию о телефонах, почтовых адресах, адресах электронной почты, любую другую персональную информацию для целей иных, нежели тематика Сайта (поиск работы и сотрудников).</li>
                          </ul>
                          <p>5.3. Администрация Сайта вправе:</p>
                          <ul class="list-unstyled ml-4">
                          <li> 5.3.1. Собирать информацию о способах использования Сайта (наиболее часто используемые функции, настройки, предпочитаемое время и продолжительность работы с Сайтом), для улучшения работы Сайта, диагностики и профилактики сбоев Сайта.</li>

                          <li> 5.3.2. Вносить в одностороннем порядке изменения в Соглашение путем издания его новых редакций.</li>

                          <li> 5.3.3. Временно прекращать работу Сайта, а равно частично ограничивать или полностью прекращать доступ к Сайту до завершения необходимого технического обслуживания и (или) модернизации Сайта. Пользователь не вправе требовать возмещения убытков за такое временное прекращение оказания услуг или ограничение доступности Сайта.</li>

                          <li> 5.3.4. Для целей исполнения настоящего Соглашения обрабатывать персональные данные Пользователя, предоставленные им, в соответствии Политикой конфиденциальности, являющейся приложением к настоящему Соглашению.</li>

                          <li> 5.3.5. В случае выявления нарушений приостановить или прекратить доступ Пользователя к отдельным Сервисам, включая блокирование доступа к Личному кабинету, а также удалить резюме и/или Интервью или отказать в его размещении.</li>

                          <li> 5.3.6. В любое время по своему усмотрению без объяснения причин удалять любую информацию, размещенную Пользователем на Сайте, которая, по мнению Администрации Сайта, нарушает и/или может нарушить законодательство, условия настоящего Соглашения, права других Пользователей Сайта или третьих лиц.</li>
                          </ul>
                          <p>5.4. Пользователь вправе:</p>
                          <ul class="list-unstyled ml-4">
                          <li> 5.4.1. Использовать Сайт в пределах и способами, предусмотренными Соглашением, в том числе:</li>
                              <ul class="ml-8">
                                <li>осуществлять поиск информации на сайте</li>
                                <li>получать информацию на сайте </li>
                                <li> создавать информацию для сайта </li>
                                <li> комментировать контент, выложенный на сайте </li>
                                <li>копировать информацию на другие сайты с указанием источника </li>
                                <li>требовать от администрации скрытия любой информации о пользователе </li>
                                <li>требовать от администрации скрытия любой информации переданной пользователем сайту </li>
                                <li>использовать информацию сайта в личных некоммерческих целях </li>

                              </ul>
                          <li> 5.4.2. Пользоваться службой поддержки, направляя через интерфейс Сайта соответствующие обращения.</li>

                          <li> 5.4.3. Пользоваться иными правами, предоставленными ему Соглашением и законодательством Российской Федерации.</li>

                          </ul>
                          <h5 class="card-title">6. Интеллектуальная собственность и порядок использования Сайта</h5>

                          <p>6.1. При условии выполнения Пользователем Соглашения, Пользователю предоставляется простая (неисключительная) лицензия на использование Сайта с помощью персонального компьютера, мобильного телефона или другого устройства, в объеме и порядке, установленном Соглашением, без права продажи, предоставления сублицензий и переуступки.</p>

                          <p>6.2. Сайт содержит результаты интеллектуальной деятельности, принадлежащие Администрации Сайта.</p>

                          <p>6.3. Используя Сайт, Пользователь признает и соглашается с тем, что все содержимое Сайта, и структура содержимого Сайта защищены авторским правом и другими правами на результаты интеллектуальной деятельности, и что указанные права являются действительными и охраняются во всех формах. Все объекты, доступные при помощи Сайта и его Сервисов, включая, помимо прочего, текстовые и графические материалы, программы, базы данных, логотипы, товарные знаки являются объектами исключительных прав Администрации Сайта и других правообладателей и не могут быть использованы иным образом без предварительного разрешения правообладателя, если на странице Сайта, содержащей такую информацию, явно не указано обратное. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе, отображение во фрейме и т.д.
                            </p>
                          <p>6.4. Право на использование доменного имени skillrank.ru, включая все уровни указанного домена, принадлежит Администрации Сайта.</p>

                          <p>6.5. В соответствии с условиями Соглашения Администрация Сайта предоставляет Пользователю право использования Сайта следующими способами:</p>
                          <ul class="ml-4">


                          <li>Использовать Сайт для просмотра, ознакомления с Контентом, реализации иного функционала Сайта, в том числе, путем воспроизведения на мониторе (экране) соответствующего устройства Пользователя;</li>

                          <li>Загружать в оперативную память устройства для целей использования Сайта.</li>
                          </ul>
                          <p>6.6. Без ущерба для универсального характера положений, изложенных в п. 6.3, во избежание сомнений, Пользователю запрещается:</p>
                            <ul class="ml-4">

                            <li>Адаптировать, модифицировать или иным образом перерабатывать программную часть Сайта, в том числе, осуществлять перевод на другие языки;</li>

                            <li>Использовать программные средства (скрипты, роботы) для считывания информации с Сайта;</li>

                            <li>Нарушать целостность защитной системы или осуществлять какие-либо действия, направленные на обход, снятие или деактивацию технических средств защиты; использовать какие-либо программные коды, предназначенные для искажения, удаления, повреждения, имитации или нарушения целостности Сайта, передаваемой информации или протоколов;</li>

                            <li>Использовать информацию, полученную на Сайте для использования противоречащим закону способом.</li>
                          </ul>
                          <p>6.7. Любое использование принадлежащих Администрации Сайта авторских материалов с не допускается без письменного согласия Администрации Сайта.</p>

                          <p>6.8. Не допускается внесение каких-либо изменений и/или дополнений в авторские материалы, запрещена любая переработка авторских материалов.</p>

                          <h5 class="card-title">7. Пользовательский контент</h5>

                          <p>7.1. Администрация Сайта предоставляет возможность Пользователю загружать на Сайт Пользовательский Контент (Интервью, фотографии и другие материалы).</p>

                          <p>7.2. Пользователь гарантирует, что является обладателем исключительных прав на данную фотографию либо иным законным правообладателем.</p>

                          <p>7.3. Принимая условия настоящего Соглашения, Пользователь предоставляет Администрации Сайта простую (неисключительную) безвозмездную лицензию на Пользовательский контент.</p>

                          <p>7.4. Указанная простая (неисключительная) лицензия на использование Пользовательского контента предоставляется Администрации Сайта одновременно с добавлением Пользователем Контента на Сайт на весь срок действия исключительных прав на объекты авторских и/или смежных прав, образующих такой Контент, для использования на территории всех стран мира. В рамках указанной лицензии Администрации Сайта предоставляется право на воспроизведение Контента; распространение; публичный показ; доведение до всеобщего сведения; право на переработку и сублицензирование.
                            </p>
                          <p>7.5. При отсутствии в составе Пользовательского контента объектов интеллектуальных прав, Пользователь предоставляет Администрации Сайта входящие в состав Контента сведения и прочие информационные материалы для использования любым способом, включая право осуществлять запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение таких данных, передачу (распространение, предоставление третьим лицам).</p>

                          <p>7.6. Пользователь понимает, что Администрация Сайта лишь предоставляет Пользователю возможность размещения Пользовательского Контента. Пользователь несет всю полноту ответственности за соответствие содержания размещаемого Пользователем Контента требованиям законодательства.</p>

                          <p>7.7. Пользователь имеет ясное представление о том, что Администрация Сайта имеет право по своему усмотрению отказать Пользователю в размещении им Контента или удалить этот Контент.</p>

                          <p>7.8. Пользователю запрещается размещать на Сайте информацию, которая:</p>
                          <ul class="list-unstyled ml-4">
                          <li> 7.8.1. нарушает авторские и смежные права; </li>

                          <li>7.8.2. содержит спам; </li>

                          <li>7.8.3. противоречит общепризнанным нормам морали, порочит честь и достоинство или деловую репутацию или нарушают неприкосновенность частной жизни третьих лиц;</li>

                          <li>7.8.4. содержит угрозы, нецензурную лексику, порнографические материалы; сцены насилия, жестокого обращения с животными; описание средств и способов суицида; экстремистские материалы; пропагандируют преступную деятельность или содержат советы, инструкции или руководства по совершению преступных действий;</li>

                          <li>7.8.5. пропагандирует и/или способствует разжиганию расовой, религиозной, этнической ненависти или вражды, пропагандирует фашизм или идеологию расового превосходства;</li>

                          <li>7.8.6. содержит пропаганду наркотических средств, психотропных веществ, алкоголя;</li>

                          <li>7.8.7. включает компьютерные коды, предназначенные для нарушения, уничтожения либо ограничения функциональности Сайта, любого компьютерного или телекоммуникационного оборудования, а также программ;</li>

                          <li>7.8.8. содержит неправомерно используемую личную информацию третьих лиц (включая, но не ограничиваясь, упоминание любых адресов и телефонных номеров либо иных персональных; нарушает права и интересы граждан и юридических лиц или требования законодательства Российской Федерации.</li>
                        </ul>
                          <p>7.9. Использование резюме, описаний вакансий недопустимо ни с какими целями, кроме соответствующих
                            тематике Сайта (поиск работы, сотрудников, получение информации о рынке труда).
                            При этом, Пользователю запрещается распространять/передавать любые данные, предоставленные
                            другим Пользователем, без письменного разрешения лица, разместившего такие сведения.
                            В том числе, Пользователю запрещается использовать e-mail, номер телефона другого Пользователя
                            для целей рассылки сообщений, а также для действий, совершаемых без ведома или
                            согласия другой стороны.</p>

                          <h5 class="card-title">8. Ответственность сторон</h5>

                          <p>8.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств в соответствии с условиями Соглашения и законодательством Российской Федерации.</p>

                          <p>8.2. Сайт предоставляется в состоянии «как есть», без гарантийных обязательств или какой-либо обязанности по устранению недостатков, эксплуатационной поддержке и усовершенствованию.</p>

                          <p>8.3. Администрация Сайта не несет ответственности за технические перебои в работе Сайта. Вместе с тем, Администрация Сайта обязуется принимать все разумные меры для предотвращения таких перебоев. Администрация Сайта также не гарантирует сохранности информации, размещенной на Сайте и возможности бесперебойного доступа к Личному кабинету.</p>

                          <p>8.4. Сервисы Сайта в любой момент могут изменяться, дополняться, обновляться, менять форму и характер функциональных возможностей без предварительного уведомления Пользователя. Администрация Сайта вправе по собственному усмотрению прекратить, приостановить функционирование Сервисов без предварительного уведомления.</p>

                          <p>8.5. Администрация Сайта не несет ответственности за любые действия Пользователя, связанные с использованием предоставленных прав использования Сайта; за ущерб любого рода, понесенный Пользователем из-за утраты и/или разглашения своих данных либо в процессе использования Сайта.</p>

                          <p>8.6. Администрация Сайта не обязана осуществлять проверку содержания, подлинности и безопасности информации, размещаемой Пользователем, а также проверку ее соответствия требованиям законодательства и наличия у Пользователя необходимого объема прав на ее распространение или использование.</p>

                          <p>8.7. В случае если какое-либо третье лицо предъявляет Администрации Сайта претензию в отношении прав на интеллектуальную собственность, иную претензию в связи с нарушением Пользователем законодательства, Пользователь обязуется компенсировать Администрации Сайта все расходы и потери, оплатить любые компенсации и прочие затраты, связанные с такой претензией.</p>

                          <p>8.8. Поскольку идентификация пользователей интернет сайтов затруднена по техническим причинам, Администрация Сайта не отвечает за то, что Пользователи Сайта являются действительно теми, за кого себя выдают, и не несет ответственности за убытки, причиненные Пользователям или другим лицам в связи с этим.</p>

                          <p>8.9. Администрация Сайта не является представителем ни Кандидатов, публикующих на Сайте свои Интервью, ни HR, размещающих вакансии, поэтому не может отвечать ни за какие договоренности и финансовые обязательства, возникающие между Пользователями.</p>

                          <p>8.10. Администрация Сайта не гарантирует Кандидату действительность вакансий, размещенных HR на Сайте, а равно любых предложений, сделанных Кандидату с помощью Сайта, с использованием контактной информации, находящейся в базе данных Сайта.</p>

                          <p>8.11. Администрация Сайта прилагает все возможные усилия для того, чтобы избежать несанкционированного использования персональных данных Пользователей, однако не несет ответственности за возможное нецелевое использование персональных данных Пользователей, произошедшее из-за технических неполадок в программном обеспечении, серверах, находящихся вне контроля Администрации Сайта либо произошедшее по вине других пользователей или третьих лиц.</p>

                          <p>8.12. Администрация Сайта не несет ответственности за использование (как правомерное, так и неправомерное) третьими лицами Интервью, размещенного на Сайте, включая его воспроизведение и распространение, осуществленные как в рамках Сайта, так и иными возможными способами.</p>

                          <h5 class="card-title">9. Изменение условий Соглашения</h5>

                          <p>9.1. Администрация Сайта оставляет за собой право вносить изменения в текст настоящего Соглашения, без специального уведомления об этом Пользователей путем публикации новой редакции Соглашения.</p>

                          <p>9.2. Пользователь обязан регулярно отслеживать изменения, вносимые в Соглашение. Если Пользователь не согласен с новой редакцией Соглашения, то он обязан незамедлительно прекратить использование Сайта. В случае если после вступления в действие новой редакции Соглашения Пользователь продолжает использовать Сайт, то такой Пользователь тем самым подтверждает свое полное согласие с новой редакцией Соглашения.</p>

                          <h5 class="card-title">10. Разрешение споров</h5>

                          <p>10.1. Претензионный порядок досудебного урегулирования споров, вытекающих из настоящего Соглашения, является для Сторон обязательным.</p>

                          <p>10.2. Срок рассмотрения претензионного письма составляет 30 дней со дня получения последнего адресатом.</p>

                          <p>10.3. Администрация Сайта вправе не рассматривать обращения Пользователя: не содержащие информацию и документы, необходимые для рассмотрения обращения; содержащие ложную информацию и (или) документы, не обладающими признаками достоверности; в отношении вопросов, по которым ранее был направлен ответ Пользователю; содержащие оскорбления, угрозы или обращения, изложенные в резкой негативной форме.</p>

                          <p>10.4. Все споры, вытекающие из настоящего Соглашения, подлежат рассмотрению в суде в соответствии с законодательством РФ.</p>

                          <h5 class="card-title">11. Правила оказания услуг</h5>

                          <p>11.1. На бесплатной основе Пользователю предоставляется ограниченый доступ к сайту.</p>
                          <p>11.2. Платный доступ оформляется в виде подписки с постоянными и безакцептными платежами за недельный или месячный период (в зависимости от выбранного Пользователем тарифа).</p>
                          <p>11.3. Стоимость платного доступа составляет 690 рублей (включая налоги) или 1990 рублей (включая налоги) (в зависимости от выбранного Пользователем тарифа) </p>
                          <p>11.4. При оформлении пллатного доступа Пользователю предоставляется 3-х дневный пробный период. По истечении пробного периода с указанного Пользователем счета спишется сумма, равная стоимости подписки</p>
                          <p>11.5. По истечении срока, указанного в тарифе, с указанного Пользователем счета аватоматически спишется сумма, необходимая для переоформления подписки.</p>
                          <p>11.6. В случае отмены Пользователем подписки, полный доступ к сайту будет предоставлен до момента окончания тарифного периода.</p>
                          <ul class="list-unstyled ml-4">
                          <li>11.6.1. Отмена подписки происходит в разделе "Корзина"->"Подписка" на сайте или через ссылку, указанную в смс-уведомлении.</li>
                          </ul>
                          <p>11.7. В качестве расчетной валюты используются рубли.</p>



                          <h5 class="card-title">12. Заключительные положения</h5>

                          <p>12.1. Ничто в Соглашении не может пониматься как установление между Пользователем и Администрацией Сайта агентских отношений, отношений по совместной деятельности либо каких-то иных отношений, прямо не предусмотренных Соглашением.</p>

                          <p>12.2. При заключении, исполнении и расторжении Соглашения Стороны вправе использовать электронные документы, удостоверенные простой электронной подписью. Простой электронной подписью признается электронная подпись, которая посредством использования логина и пароля Пользователя или указанного при использовании Сервиса адреса электронной почты Пользователя (ключ электронной подписи) подтверждает факт формирования электронной подписи непосредственно Пользователем.</p>
                          <p>12.3. Электронные документы, подписанные простой электронной подписью, признаются равнозначными документам на бумажных носителях, подписанным собственноручной подписью. Администрация Сайта определяет Пользователя, которому соответствует простая электронная подпись, по используемому Пользователем логину и паролю, указанным при регистрации/или авторизации на Сайте, – в случае совершения любых действий по использованию Сайта, либо по используемому Пользователем адресу электронной почты – в случае поступления Администрации Сайта сообщений с такого адреса. Любые действия, совершенные с использованием Учетных данных/электронной почты определенного Пользователя, считаются совершенными таким Пользователем.</p>
                          <p>12.4. Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации. Везде по тексту настоящего Соглашения под термином «законодательство» понимается законодательство Российской Федерации.</p>

                          <p>12.5. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не окажет влияния на действительность или применимость остальных положений Соглашения.</p>

                          <p>12.6. Обращения, предложения и претензии к Администрации Сайта в связи с Соглашением и со всеми вопросами по функционированию Сайта, нарушениям прав и интересов третьих лиц при его использовании, а также запросы уполномоченных законодательством лиц могут быть направлены по реквизитам, указанным в разделе 12 Соглашения.</p>

                          <p>12.7. Неотъемлемыми частями Соглашения являются:
                            <br />
                          Приложение №1 – Политика конфиденциальности.
                          </p>

                          <h5 class="card-title">13. Реквизиты Администрации Сайта</h5>
                          <ul class="list-unstyled">
                            <li>Индивидуальный предприниматель "Гончаренко Игорь Сергеевич"</li>
                            <li>Адрес: 140073, Московская Область, п.Томилино, мкр Птицефабрика, дом 34</li>
                            <li>ИНН: 667327225183</li>
                            <li>ОГРНИП: 321508100088630</li>
                            <!--
                            <li> Банковские реквизиты:</li>
                            <li>Расчётный счёт: 40802810838000241200</li>
                            <li>БИК: 044525225</li>
                            <li> Банк: ПАО СБЕРБАНК</li>
                            <li> Корр. счёт: 30101810400000000225</li>
                            <br>
                            <li> Тел: +7(909)163-5804</li>
                            -->
                            <li> Email: <a href="mailto:igor_goncharenko@skillrank.ru" class="text-primary">igor_goncharenko@skillrank.ru</a></li>
                            <li> Telegram: IgorSkillrank</li>
                          </ul>

                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Terms & Conditions -->
    <!--end section-->
    <Footer />
    <!-- Footer End
    <Switcher />
    -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
